























































































































































































































































import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import TabsInfoMixin from '../../../devices/TabsInfoMixin';
import { Mixins } from 'vue-mixin-decorator';
import { Props, Global } from '@/store';
import { i18n } from '@/main';
import cloneDeep from 'lodash/cloneDeep';
import omit from 'lodash/omit';
import isEqual from 'lodash/isEqual';
import { v4 as uuidv4 } from 'uuid';
import ConfirmDialog from '@/components/dialogs/ConfirmDialog.vue';
import BreakerMonitor from './BreakerMonitor.vue';
import LineMonitor from './LineMonitor.vue';
import Contactor from './Contactor.vue';

@Component({
    components: {
        ConfirmDialog,
        BreakerMonitor,
        LineMonitor,
        Contactor
    }
})
export default class Connections extends Mixins<TabsInfoMixin>(
  TabsInfoMixin
) {
  // @ts-ignore
  @Prop() device;
  @Prop() extentioned_teltonica;
  @Prop() mqtt;
  @Prop() streamControl;
  @Prop() streamInputs;
  @Prop() teltonica_child_input_streams;
  @Prop() teltonica_child_output_streams;
  @Prop() propsList;
  @Prop() mqtt_version;

  @Props.Action('editProp') editProp;
  @Props.State('list') projectProperties;
  @Global.State('lang') lang;
  @Global.State('readonly_user') readonly_user;

  actions = [{ text: i18n.t('Do nothing'), value: 'nothing' }, { text: i18n.t('Create event'), value: 'event' }];
  contactorIcon = require('@/assets/images/cabinet_details_icons/contactor.png');
  breakerMonitorIcon = require('@/assets/images/cabinet_details_icons/breaker_monitor.png');
  energizedIcon = require('@/assets/images/cabinet_details_icons/energized.png');
  trippedIcon = require('@/assets/images/cabinet_details_icons/tripped.png');
  offIcon = require('@/assets/images/cabinet_details_icons/off_line_monitor.jpeg');
  energizedIconLineMonitor = require('@/assets/images/cabinet_details_icons/energized_line_monitor.jpeg');
  addChildIcon = require('@/assets/images/cabinet_details_icons/add_child.png');
  lineMonitorIcon = require('@/assets/images/cabinet_details_icons/line_monitor.jpg');
  
  loading = true;
  connections = {};
  deleteConnectionDialog = false;
  connectionDialog = false;
  quitDialog = false;
  currentConnection = {
    id: '',
    desc: '',
    elements: []
  };
  currentElement = null;
  deletedControls = [];
  unusedControls = [];
  noData = false;

  inputItems = [
    {text: 'Input 1', value: 1},
    {text: 'Input 2', value: 2}, 
    {text: 'Input 3', value: 3}, 
    {text: 'Input 4', value: 4}, 
    {text: 'Input 5', value: 5}, 
    {text: 'Input 6', value: 6}, 
    {text: 'Input 7', value: 7}, 
    {text: 'Input 8', value: 8}, 
    {text: 'Input 9', value: 9}, 
    {text: 'Input 10', value: 10},
    {text: 'Input 11', value: 11}, 
    {text: 'Input 12', value: 12}
  ];

  outputItems = [{text: 'Output 1', value: 1}, {text: 'Output 2', value: 2}, {text: 'Output 3', value: 3}, {text: 'Output 4', value: 4}];
  scheduleProp = null;
  original_connections = null;
  original_schedules = null;

  async created() {
    this.loading = true;
    const scheduleProp = this.propsList.find((el) => el.name === 'device.schedule');
    this.original_schedules = scheduleProp ? scheduleProp.value : null;
    this.scheduleProp = this.original_schedules && cloneDeep(this.original_schedules) || null;
    if (this.extentioned_teltonica && this.device || !this.extentioned_teltonica){
        const deviceConnectionsProp = this.propsList.find((el) => el.name === 'device.connections');
        this.original_connections = deviceConnectionsProp ? deviceConnectionsProp.value : null;
        this.connections = this.original_connections && cloneDeep(this.original_connections) || null;

        if (this.scheduleProp && this.connections) {
            Object.values(this.connections).forEach((connection) => {
                Object.values(connection['elements']).forEach((el) => {
                    if (el['element'] === 'Contactor') {
                        this.outputChanged(null, el['control'], false);
                    }else {
                        this.inputChanged(null, el['input'], false);
                    }
                    if (el['children'].length) {
                        el['children'].forEach((child) => {
                            if (child['element'] === 'Contactor') {
                                this.outputChanged(null, child['control'], false);
                            }else {
                                this.inputChanged(null, child['input'], false);
                            }
                        });
                    }
                });
            });
        }else this.noData = true;
    }
    this.loading = false;
  }

  get elementsToAdd() {
    if (this.getNumberOfContactors() > 0) return ['Breaker Monitor', 'Line Monitor'];
    return ['Contactor', 'Breaker Monitor', 'Line Monitor'];
  }

  getNumberOfContactors() {
    return Object.values(this.currentConnection['elements']).filter((el) => el['element'] === 'Contactor').length;
  }
  
  updateScheduleProp(schedules) {
    this.scheduleProp = schedules;
  }

  updateSchedule(output_num, field_name, value) {
    if (!this.scheduleProp[output_num]) {
        this.scheduleProp[output_num] = {};
    }
    this.scheduleProp[output_num][field_name] = value;
  }

  outputChanged(item, output, deleteMode) {
    this.outputItems.forEach((el) => {
        if (el.value === output) {
            el['disabled'] = true;
        }
        else if (item && el.value === item.control) {
            el['disabled'] = false;
        }
        if (deleteMode && el.value === output) {
            el['disabled'] = false;
        }
    });
    if (item) {
        item.control = output;
    }
  }

  inputChanged(item, input, deleteMode) {
    this.inputItems.forEach((el) => {
        if (el.value === input) {
            el['disabled'] = true;
        }
        else if (item && el.value === item.input) {
            el['disabled'] = false;
        }
        if (deleteMode && el.value === input) {
            el['disabled'] = false;
        }
    });
    if (item) {
        item.input = input;
    }
  }

  createNewConnection() {
    this.currentConnection.id = uuidv4();
    this.connectionDialog = true;
  }

  showSaveMessage(){
    if (!isEqual(this.original_connections, this.connections) || !isEqual(this.original_schedules, this.scheduleProp)) {
        this.quitDialog = true;
    }else {
        this.currentConnection = {
            id: '',
            desc: '',
            elements: []
        };
        this.connectionDialog = false;
    }
  }

  quit() {
    this.connections = cloneDeep(this.original_connections);
    this.scheduleProp = cloneDeep(this.original_schedules);
    const usedInputs = [];
    const usedOutputs = [];
    Object.values(this.connections).forEach((connection) => {
        Object.values(connection['elements']).forEach((el) => {
            if (el['element'] === 'Contactor') {
                usedOutputs.push(el['control']);
            }
            else {
                usedInputs.push(el['input']);
            }
            if (el['children'].length) {
                el['children'].forEach((child) => {
                    if (child['element'] === 'Contactor') {
                        usedOutputs.push(el['control']);
                    }
                    else {
                        usedInputs.push(el['input']);
                    }
                });
            }
        });
    });

    this.outputItems.forEach((el) => {
       if (usedOutputs.includes(el.value)) {
            el['disabled'] = true;
       }
       else {
            el['disabled'] = false;
       }
    });

    this.inputItems.forEach((el) => {
        if (usedInputs.includes(el.value)) {
            el['disabled'] = true;
        }
        else {
            el['disabled'] = false;
        }
    });
    
    this.currentConnection = {
        id: '',
        desc: '',
        elements: []
    };
    this.connectionDialog = false;
    this.quitDialog = false;
  }

  addElement(el, parent) {
    let newItem = {};
    switch (el) {
        case 'Contactor':
            newItem = {
                id: uuidv4(),
                element: el,
                description: '',
                type: 'Standard',
                control: '',
                configuration: '',
                monitorConfiguration: '',
                input: '',
                energizedLabel: 'Energized',
                offLabel: 'OFF',
                energizedToOffAction: '',
                offToEnergizedAction: '',
                contactorOnMonitorOffAction: '',
                children: []
            };
            const index = this.deletedControls.indexOf(newItem['control']);
            if (index > -1) {
                this.deletedControls.splice(index, 1);
            }
            if (parent) {
                this.addChild(parent, newItem);
            }
            break;
        case 'Breaker Monitor':
            newItem = {
                id: uuidv4(),
                element: el,
                description: '',
                input: '',
                energizedLabel: 'Energized',
                trippedLabel: 'Tripped',
                energizedToTrippedAction: '',
                trippedToEnergizedAction: '',
                configuration: '',
                children: []
            };
            if (parent) {
                this.addChild(parent, newItem);
            }
            break;
        case 'Line Monitor':
            newItem = {
                id: uuidv4(),
                element: el,
                description: '',
                input: '',
                energizedLabel: 'Energized',
                offLabel: 'OFF',
                energizedToOffAction: '',
                offToEnergizedAction: '',
                configuration: '',
                children: []
            };
            if (parent) {
                newItem['parentElement'] = parent.element;
                this.addChild(parent, newItem);
            }
            break;
    }
    if (!parent) {
        this.currentConnection['elements'] = {...this.currentConnection['elements'], [newItem['id']]: newItem };
    }
  }

  addChild(parent, child) {
    parent['children'].push(child);
  }

  deleteElement(id, parentId, grandParentId) {
    let element;

    if (grandParentId) {
        const parentElementIndex = this.currentConnection['elements'][grandParentId]['children'].findIndex((el) => el.id === parentId);
        const parentElement = this.currentConnection['elements'][grandParentId]['children'][parentElementIndex];
        const elementIndex = parentElement['children'].findIndex((el) => el.id === id);
        element = parentElement['children'][elementIndex];
        this.currentConnection['elements'][grandParentId]['children'][parentElementIndex]['children'].splice(elementIndex, 1);
    }else if (parentId) {
        element = this.currentConnection['elements'][parentId]['children'].find((el) => el.id === id);
        this.currentConnection['elements'][parentId]['children'] = 
            this.currentConnection['elements'][parentId]['children'].filter((child) => child.id !== id);
    }else {
        element = Object.values(this.currentConnection['elements']).find((el) => el.id === id);
        element['children'].forEach((child) => {
            if (child.element === 'Contactor') {
                this.deletedControls.push(child.control);
                this.outputChanged(null, child.control, true);
                if (element.input) {
                    this.inputChanged(null, element.input, true);
                }
            }
            else {
                this.inputChanged(null, child.input, true);
            }
            child['children'].forEach((grandChild) => {
                if (grandChild.element === 'Contactor') {
                    this.deletedControls.push(grandChild.control);
                    this.outputChanged(null, grandChild.control, true);
                    if (element.input) {
                        this.inputChanged(null, element.input, true);
                    }
                }
                else {
                    this.inputChanged(null, grandChild.input, true);
                }
            });
        });
        // @ts-ignore
        this.currentConnection['elements'] = omit(this.currentConnection['elements'], id);
    }

    if (element.element === 'Contactor') {
        this.deletedControls.push(element.control);
        this.outputChanged(null, element.control, true);
        if (element.input) {
            this.inputChanged(null, element.input, true);
        }
    }else {
        this.inputChanged(null, element.input, true);
    }
  }

  saveConnection() {
    // @ts-ignore
    if (this.$refs.contactor && this.$refs.contactor.length) {
        // @ts-ignore
        this.$refs.contactor.forEach((c) => {
            c.calculateNewSchedules();
        });
        const first_contractor_element = this.$refs.contactor[0];
        if (Object.values(first_contractor_element.errorMsg).some((msg) => msg !== '')) return;
            
        this.updateScheduleProp(first_contractor_element.schedules);
    }

    this.connections = { ...this.connections, [this.currentConnection.id]: this.currentConnection };
    this.setUnusedControls();
    this.currentConnection = {
        id: '',
        desc: '',
        elements: []
    };

  
    if (this.deletedControls.length || this.unusedControls.length) {
        let controls_to_clear = this.deletedControls.map((control) => control.toString()).concat(this.unusedControls);
        controls_to_clear = [...new Set(controls_to_clear)];
        controls_to_clear.forEach((control) => this.scheduleProp[control] = {is_astronomical: false, active: false});
        this.deletedControls = [];
        this.unusedControls = [];
    }

    this.editProp({
        _notify_msg: null,
        name: 'device.schedule',
        objectID: this.device.id,
        objectType: 'devices',
        value: this.scheduleProp
    });

    this.editProp({
      _notify_msg: this.$t('Connection settings were successfully updated'),
      name: 'device.connections',
      objectID: this.device.id,
      objectType: 'devices',
      value: this.connections
    });

    this.original_connections = cloneDeep(this.connections);
    this.connectionDialog = false;
  }

  setUnusedControls(){
    const elements = Object.values(this.connections).reduce((acc: object, connection) => ({...acc, ...connection['elements']}), {});
    const elements_values = Object.values(elements) || [];
    // const used_inputs = elements_values.filter((element) => element.input).map((element) => element.input);
    const used_outputs = elements_values.filter((element) => element.control).map((element) => element.control);
    this.unusedControls = Object.entries(this.scheduleProp)
        .filter(([output_num, value]) => !isNaN(+output_num) && output_num !== '' && !used_outputs.includes(+output_num))
        .map(([output_num, value]) => output_num);
  }

  showConnection(connection) {
    this.currentConnection = this.connections[connection];
    this.connectionDialog = true;
  }

  deleteConnection(connection) {
    if (!this.connections[connection]) {
        Object.values(this.currentConnection['elements']).forEach((el) => {
            if (el['element'] === 'Contactor') {
                this.deletedControls.push(el.control);
                this.outputChanged(null, el['control'], true);
                if (el.input) {
                    this.inputChanged(null, el.input, true);
                }
            }
            else {
                this.inputChanged(null, el['input'], true);
            }
            if (el['children'].length) {
                el['children'].forEach((child) => {
                    if (child['element'] === 'Contactor') {
                        this.deletedControls.push(child.control);
                        this.outputChanged(null, child['control'], true);
                        if (el['input']) {
                            this.inputChanged(null, el['input'], true);
                        }
                    }
                    else {
                        this.inputChanged(null, child['input'], true);
                    }
                    child['children'].forEach((grandChild) => {
                        if (grandChild.element === 'Contactor') {
                            this.deletedControls.push(grandChild.control);
                            this.outputChanged(null, grandChild.control, true);
                            if (child['input']) {
                                this.inputChanged(null, child['input'], true);
                            }
                        }
                        else {
                            this.inputChanged(null, grandChild.input, true);
                        }
                    });
                });
            }
        });
        this.currentConnection = {
            id: '',
            desc: '',
            elements: []
        };
        this.connectionDialog = false;
        this.deleteConnectionDialog = false;
        return;
    }

    Object.values(this.connections[connection]['elements']).forEach((el) => {
        if (el['element'] === 'Contactor') {
            this.deletedControls.push(el['control']);
            this.outputChanged(null, el['control'], true);
            if (el['input']) {
                this.inputChanged(null, el['input'], true);
            }
        }
        else {
            this.inputChanged(null, el['input'], true);
        }
        if (el['children'].length) {
            el['children'].forEach((child) => {
                if (child['element'] === 'Contactor') {
                    this.deletedControls.push(child.control);
                    this.outputChanged(null, child['control'], true);
                    if (el['input']) {
                        this.inputChanged(null, el['input'], true);
                    }
                }
                else {
                    this.inputChanged(null, child['input'], true);
                }
                child['children'].forEach((grandChild) => {
                    if (grandChild.element === 'Contactor') {
                        this.deletedControls.push(grandChild.control);
                        this.outputChanged(null, grandChild.control, true);
                        if (child['input']) {
                            this.inputChanged(null, child['input'], true);
                        }
                    }
                    else {
                        this.inputChanged(null, grandChild.input, true);
                    }
                });
            });
        }
    });
    delete this.connections[connection];

    this.editProp({
      _notify_msg: this.$t('Connection settings were successfully updated'),
      name: 'device.connections',
      objectID: this.device.id,
      objectType: 'devices',
      value: this.connections
    });

    if (this.deletedControls.length) {
        this.deletedControls.forEach((c) => this.scheduleProp[c.toString()] = {is_astronomical: false, active: false});
        this.editProp({
            _notify_msg: null,
            name: 'device.schedule',
            objectID: this.device.id,
            objectType: 'devices',
            value: this.scheduleProp
        });
        this.deletedControls = [];
    }

    this.currentConnection = {
        id: '',
        desc: '',
        elements: []
    };
    this.original_connections = cloneDeep(this.connections);
    this.connectionDialog = false;
    this.deleteConnectionDialog = false;
  }
}
