
























































































































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Props, Global } from '@/store';
import { Prop, Watch } from 'vue-property-decorator';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import PropUtils from '@/modules/PropUtils';
import GeneralStep from './GeneralStep.vue';
import ScheduleStep from './ScheduleStep.vue';
import TriggersStep from './TriggersStep.vue';
import ConfirmDialog from '@/components/dialogs/ConfirmDialog.vue';
import Utils from '@/modules/Utils';

@Component({
    components: {
        GeneralStep,
        ScheduleStep,
        TriggersStep,
        ConfirmDialog
    }
})
export default class ConnectionDialog extends Vue{
    @Global.State('readonly_user') readonly_user;
    @Global.State('lang') lang;
    @Global.State('dusk_dawn_data') dusk_dawn_data;
    @Global.State('timezone') projectTimezone;

    @Prop() dialog_data;
    @Prop() connections_count;
    @Prop() linked_inputs;
    @Prop() linked_outputs;

    prop_utils = PropUtils;
    drawer = true;
    contactor_data = null;
    original_connection = null;
    current_connection = null;
    current_schedule = null;
    current_daily_schedule = null;
    current_daily_schedule_display = null;
    original_daily_schedule_display = null;
    types = [{text: 'Standard', value: 'Standard'}, {text: 'Monitored', value: 'Monitored'}];
    selected_tab = 'general';
    loading = true;
    current_step = 1;
    update_schedules_data = false;
    discard_step_changes_dialog = false;
    discard_all_changes_dialog = false;
    update_triggers_data = false;
    apply_btn_disabled = true;
    is_days_schedule_error = false;
    delete_dialog = false;

    mounted() {
        if (this.dialog_data.current_connection) {
            this.current_connection = cloneDeep(this.dialog_data.current_connection);
            if (this.dialog_data.current_connection.type === 'output'){
                this.initialSchedules();
                this.initialTriggers();
            }
            this.original_connection = cloneDeep(this.current_connection);
            this.update_triggers_data = this.dialog_data.add;
        }
        this.loading = false;
    }

    initialSchedules(){
        this.current_schedule = cloneDeep(this.dialog_data.connection_schedule);
        this.current_daily_schedule = cloneDeep(this.dialog_data.connection_daily_schedule);
        this.current_daily_schedule_display = this.getDailyScheduleDisplayFormat();
        this.original_daily_schedule_display = cloneDeep(this.current_daily_schedule_display);
        this.update_schedules_data = this.dialog_data.add;
    }

    initialTriggers(){
        this.current_connection.gpio_on_state_changed.forEach((trigger) => trigger.inputs = trigger.inputs.map((input) => input.toString()));
    }

    setIsDaysScheduleError(status){
        this.is_days_schedule_error = status;
    }

    checkOpenDialog(){
        this.$nextTick(() => {
            if (!this.apply_btn_disabled || this.is_days_schedule_error) {
                this.discard_step_changes_dialog = true;
            }else {
                this.current_step = 1;
            }
        });
    }

    getDailyScheduleDisplayFormat(){
        const special_days_schedule = [];

        this.days_in_week.forEach((day) => {
            const next_day_num = Utils.getNextDay(day.value);
            const current_day_data = this.current_daily_schedule.find((daily_schedule) => daily_schedule.days.includes(day.value));
            const next_day_data = this.current_daily_schedule.find((daily_schedule) => daily_schedule.days.includes(next_day_num));

            if (!current_day_data) return;
            
            const point = {on: null, off: null};
            if (current_day_data.time_points.length === 2){
               const current_min_on_index = Utils.findIndexOfMinTime(current_day_data.time_points, true, 'on');
               const current_max_off_index = Utils.findIndexOfMaxTime(current_day_data.time_points, true, 'off');
               if (current_min_on_index !== -1 && current_max_off_index !== -1){
                    point.on = {...current_day_data[current_min_on_index].on};
                    point.off = {...current_day_data[current_max_off_index].off};
               }
            }else if (next_day_data){ // length = 1
                const current_max_on_index = Utils.findIndexOfMaxTime(current_day_data.time_points, true, 'on');
                const next_min_off_index = Utils.findIndexOfMinTime(next_day_data.time_points, true, 'off');
                if (current_max_on_index !== -1 && next_min_off_index !== -1) {
                    point.on = {...current_day_data.time_points[current_max_on_index].on};
                    point.off = {...next_day_data.time_points[next_min_off_index].off};
                }
            }
            if (!point.on || !point.off) return;

            const schedules_set_index = this.findIndexOfSchedules([point], special_days_schedule);
            if (schedules_set_index !== -1){
                special_days_schedule[schedules_set_index].days.push(day.value);
            }else {
                special_days_schedule.push({active: current_day_data.active, days: [day.value], time_points: [point]});
            }
        });
        return special_days_schedule;
    }

    findIndexOfSchedules(schedules_set, special_days_schedule){
        return special_days_schedule.findIndex((daily_schedule) => isEqual(schedules_set, daily_schedule.time_points));
    }

    @Watch('current_step')
    isAddModeBtnDisabled() {
        if (this.output_dialog && !this.$refs.general_step && !this.$refs.schedule_step && !this.$refs.triggers_step) return true;
        if (!this.output_dialog && !this.$refs.general_step && !this.$refs.triggers_step) return true;

        if (this.$refs.general_step && this.current_step === 1 && !(this.$refs.general_step as Vue & { validate: () => boolean }).validate()){
            return true;
        }
        if (this.$refs.schedule_step &&
            this.output_dialog && 
            this.current_step === 2 &&
            this.$refs.schedule_step['current_daily_schedule_display'] && 
            this.$refs.schedule_step['current_daily_schedule_display'].length
        ) {
            if (this.$refs.schedule_step['current_daily_schedule_display'].some((daily_schedule) => !daily_schedule.days.length)) {
                return true;
            }
        }else if (this.$refs.triggers_step && !(this.$refs.triggers_form as Vue & { validate: () => boolean }).validate()) {
            return true;
        }
        return false;
    }

    isFinishDisabled(){
        if (!this.$refs.triggers_form) return true;
        if (this.dialog_data.add && this.is_triggers_step && !(this.$refs.triggers_form as Vue & { validate: () => boolean }).validate()) {
            return true;
        }
        return false;
    }

    madeChanges(){
        let made_changes = !isEqual(this.original_connection, this.current_connection);
        if (this.output_dialog) {
            made_changes = made_changes || !isEqual(this.dialog_data.connection_schedule, this.current_schedule);
            made_changes = made_changes || !isEqual(this.original_daily_schedule_display, this.current_daily_schedule_display);
        }
        return made_changes;
    }

    setExitDialog(){
        const made_changes = this.madeChanges();
        if (made_changes){
            this.discard_all_changes_dialog = true;
        }else {
            this.$emit('setOutputDialog', false, null);
        }
    }

    updateCurrentStep(step) {
        this.current_step = step;
    }

    discardStepChanges(){
        if (this.is_schedules_step){
            this.update_schedules_data = false;
        }else if (this.is_triggers_step){
            this.update_triggers_data = false;
        }
        this.current_step = 1;
        this.discard_step_changes_dialog = false;
    }

    saveConnection(){
        this.updateCurrentStep(1);
        this.$nextTick(() => {
            let new_schedule = null;
            const device_connections = cloneDeep(this.current_connection);

            if (this.output_dialog) {
                const final_days_schedule = this.generateSpecialTimePointsForCabinet();

                new_schedule = {
                    schedule: cloneDeep(this.current_schedule),
                    days_schedule: final_days_schedule
                };

                device_connections.gpio_on_state_changed.forEach((trigger) => {
                    trigger.inputs = trigger.inputs.map((input) => +input);
                });
            }
        
            this.$emit('saveConnection', device_connections, new_schedule);
        });
    }

    deleteConnection(){
        this.$emit('deleteConnection', this.current_connection);
    }

    updateTriggers(triggers_list){
        this.current_connection.gpio_on_state_changed = cloneDeep(triggers_list);
    }

    updateSchedules(default_schedule, daily_schedule){
        this.current_schedule = cloneDeep(default_schedule);
        this.current_daily_schedule_display = cloneDeep(daily_schedule);
    }

    applyChanges(){
        if (this.is_triggers_step && !(this.$refs.triggers_form as Vue & { validate: () => boolean }).validate()) return;
        // validate scheduler step data
        if (this.is_schedules_step) {
            this.update_schedules_data = true;
        }else if (this.is_triggers_step){
            this.update_triggers_data = true;
        }
        
        this.current_step = 1;
    }

    // get output_behavior(){
    //     return [{text: this.$t('Keep Value'), value: 'keep_value'}, {text: this.$t('Momentary'), value: 'momentary'}];
    // }

    generateSpecialTimePointsForCabinet(){
        const default_schedule = this.current_schedule.time_points && this.current_schedule.time_points[0] || null;

        if (!this.current_daily_schedule_display.length) return [];
        else if (isEqual(this.current_daily_schedule_display, this.original_daily_schedule_display)) {
            if (!isEqual(this.dialog_data.connection_schedule.time_points, this.current_schedule.time_points)) {
                this.current_daily_schedule.forEach((schedule) => {
                    schedule.time_points.forEach((time_point) => {
                        if (time_point.on.default) {
                            time_point.on = {...default_schedule.on};
                        }else if (time_point.off.default){
                            time_point.off = {...default_schedule.off};
                        }
                    });
                });
            }
            return this.current_daily_schedule;
        }

        const schedule_by_day = {};
        Array.from({length: 7}, (v, i) => i + 1).forEach((day) => schedule_by_day[day] = {active: false, time_points: []});
        
        this.days_in_week.forEach((day) => {
            const day_schedules_data = this.current_daily_schedule_display.find((daily_schedule) => daily_schedule.days.includes(day.value));
            if (day_schedules_data) {
                schedule_by_day[day.value].active = day_schedules_data.active;
                // support only for 1 time point
                // start time always written in current day
                day_schedules_data.time_points.forEach((time_point) => {
                    const next_day_num = Utils.getNextDay(day.value);
                    const on_time_in_minutes = Utils.convertTimePointToMinutes(time_point.on, this.dusk_dawn_data.dusk_timestamp, this.dusk_dawn_data.dusk);
                    const off_time_in_minutes = Utils.convertTimePointToMinutes(time_point.off, this.dusk_dawn_data.dawn_timestamp, this.dusk_dawn_data.dawn);
                    const off_on_today = on_time_in_minutes < off_time_in_minutes;
                        
                    if (off_on_today) {
                        schedule_by_day[day.value].time_points = this.generateTodayTimePoints(schedule_by_day[day.value].time_points, time_point, default_schedule);
                    }else {
                        const [current_day_point, next_day_point] = this.generateTwoDaysTimePoint(schedule_by_day[day.value].time_points, time_point, default_schedule);
                        schedule_by_day[day.value].time_points = [current_day_point];
                        const min_off_time_next_day_index = Utils.findIndexOfMinTime(schedule_by_day[next_day_num].time_points, false, 'off');
                        if (min_off_time_next_day_index === -1) {
                            schedule_by_day[next_day_num].time_points.push(next_day_point);
                        }else {
                            schedule_by_day[next_day_num].time_points[min_off_time_next_day_index].off = {...next_day_point.off};
                        }
                        schedule_by_day[next_day_num].active = schedule_by_day[day.value].active;
                    }
                });
            }
        });
        return this.generateUniqueSchedulesSets(schedule_by_day);
    }

    generateUniqueSchedulesSets(schedule_by_day){
        const final_schedules_sets = [];
        Object.entries(schedule_by_day).forEach(([day, day_schedule_data]) => {
            if (!day_schedule_data['time_points'].length) return; 
            
            const find_same_schedule_index = final_schedules_sets.findIndex((schedule) => isEqual(schedule.time_points, day_schedule_data['time_points']));
            if (find_same_schedule_index === -1 || final_schedules_sets[find_same_schedule_index].active !== day_schedule_data['active']) {
                final_schedules_sets.push({
                    active: day_schedule_data['active'],
                    days: [+day],
                    time_points: [...day_schedule_data['time_points']]
                });
            }else {
                final_schedules_sets[find_same_schedule_index].days.push(+day);
            }
        });

        return final_schedules_sets;
    }

    generateTodayTimePoints(prev_time_points, new_time_point, default_schedule) {
        let first_off_point = {
            type: default_schedule.off.type,
            value: default_schedule.off.value,
            default: true
        };

        if (prev_time_points.length) {
            const prev_min_off_time_index = Utils.findIndexOfMinTime(prev_time_points, true, 'off');
            if (prev_min_off_time_index !== -1) {
                first_off_point = {...prev_time_points[prev_min_off_time_index].off};
            }
        }

        return [
            {
                off: first_off_point,
                on: {
                    type: new_time_point.on.type,
                    value: new_time_point.on.value,
                    default: false
                }
            }, 
            {
                off: {
                    type: new_time_point.off.type,
                    value: new_time_point.off.value,
                    default: false
                },
                on: {
                    type: default_schedule.on.type,
                    value: default_schedule.on.value,
                    default: true
                }
            }
        ];
    }

    generateTwoDaysTimePoint(prev_time_points, time_point, default_schedule) {
        let first_off_point = {
            type: default_schedule.off.type,
            value: default_schedule.off.value,
            default: true
        };
        if (prev_time_points.length) {
            const prev_min_off_time_index = Utils.findIndexOfMinTime(prev_time_points, true, 'off');
            if (prev_min_off_time_index !== -1) {
                first_off_point = {...prev_time_points[prev_min_off_time_index].off};
            }
        }
        const current_day_point = {
            off: first_off_point,
            on: {
                type: time_point.on.type,
                value: time_point.on.value,
                default: false
            }
        };
        const next_day_point = {
            off: {
                type: time_point.off.type,
                value: time_point.off.value,
                default: false
            },
            on: {
                type: default_schedule.on.type,
                value: default_schedule.on.value,
                default: true
            }
        };

        return [current_day_point, next_day_point];
    }

    setApplyBtnIsDisabled(value){
        this.apply_btn_disabled = value;
    }

    get is_triggers_step(){
        return this.output_dialog && this.current_step === 3 || !this.output_dialog && this.current_step === 2;
    }

    get is_schedules_step(){
        return this.output_dialog && this.current_step === 2;
    }

    get inputs_options(){
        return Array.from({length: this.connections_count.inputs}, (v, i) => i).map((address) => (address + 1).toString());
    }

    get outputs_options(){
        return Array.from({length: this.connections_count.outputs}, (v, i) => i).map((address) => (address + 1).toString());
    }

    get output_dialog(){
        return this.current_connection.type === 'output';
    }

    get days_in_week() {
        return [
            {
                en: 'Sun',
                he: 'א',
                value: 1
            },
            {
                en: 'Mon',
                he: 'ב',
                value: 2
            },
            {
                en: 'Tue',
                he: 'ג',
                value: 3
            },
            {
                en: 'Wed',
                he: 'ד',
                value: 4
            },
            {
                en: 'Thu',
                he: 'ה',
                value: 5
            },
            {
                en: 'Fri',
                he: 'ו',
                value: 6
            },
            {
                en: 'Sat',
                he: 'ש',
                value: 7
            }
        ];
    }
}

